var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标题","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'title',
        {
          rules: [{ required: true, message: '标题不能为空' }],
          initialValue: _vm.info.title
        },
      ]),expression:"[\n        'title',\n        {\n          rules: [{ required: true, message: '标题不能为空' }],\n          initialValue: info.title\n        },\n      ]"}],attrs:{"placeholder":"公告标题"}})],1),_c('a-form-item',{attrs:{"label":"描述","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'description',
        {
          rules: [{ required: true, message: '轮播图描述不能为空' }],
          initialValue: _vm.info.description
        }
      ]),expression:"[\n        'description',\n        {\n          rules: [{ required: true, message: '轮播图描述不能为空' }],\n          initialValue: info.description\n        }\n      ]"}],attrs:{"rows":"5","placeholder":"请输入轮播图描述"}})],1),_c('a-form-item',{attrs:{"label":"图片","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'upload_img_object',
          {
            rules: [{ required: true, message: '轮播图不能为空' }],
            initialValue: _vm.fileList
          }
        ]),expression:"[\n          'upload_img_object',\n          {\n            rules: [{ required: true, message: '轮播图不能为空' }],\n            initialValue: fileList\n          }\n        ]"}],attrs:{"file-list":_vm.fileList,"customRequest":_vm.customRequest,"multiple":false,"remove":_vm.removeFile},on:{"change":_vm.changeFile}},[(_vm.fileList.length < 1)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to upload ")],1):_vm._e()],1),(_vm.fileList.length > 0)?_c('img',{attrs:{"height":"300px","src":_vm.fileList[0].file_url,"alt":""}}):_vm._e()],1),_c('a-form-item',{attrs:{"label":"状态","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'status',
        {
          valuePropName: 'checked',
          initialValue: _vm.infoStatus,
        },
      ]),expression:"[\n        'status',\n        {\n          valuePropName: 'checked',\n          initialValue: infoStatus,\n        },\n      ]"}]},[_vm._v(" 是否启用 ")])],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }