<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'title',
          {
            rules: [{ required: true, message: '标题不能为空' }],
            initialValue: info.title
          },
        ]" placeholder="公告标题" />
      </a-form-item>
      <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="5" placeholder="请输入轮播图描述" v-decorator="[
          'description',
          {
            rules: [{ required: true, message: '轮播图描述不能为空' }],
            initialValue: info.description
          }
        ]" />
      </a-form-item>
      <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-upload :file-list="fileList" :customRequest="customRequest" :multiple="false" @change="changeFile"
          :remove="removeFile" v-decorator="[
            'upload_img_object',
            {
              rules: [{ required: true, message: '轮播图不能为空' }],
              initialValue: fileList
            }
          ]">
          <a-button v-if="fileList.length < 1">
            <a-icon type="upload" /> Click to upload
          </a-button>
        </a-upload>
        <img v-if="fileList.length > 0" height="300px" :src="fileList[0].file_url" alt="">
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: infoStatus,
          },
        ]">
          是否启用
        </a-checkbox>
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { rotationInfo, rotationCreate, rotationUpdate } from '@/api/rotation'
import { uploadGeneralImage, deleteGeneralFile } from '@/api/file'

export default {
  name: 'EditNoticeTemplate',
  data() {
    return {
      description: '公告编辑添加',
      value: 1,
      fontStyle: {
        fontWeight: 1000
      },
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      customLabelCol: {
        lg: { span: 4 },
        sm: { span: 7 }
      },
      customWrapperCol: {
        lg: { span: 18 },
        sm: { span: 17 }
      },
      queryData: {},
      checkPass: true,
      info: {},
      fileList: [],
      infoStatus: false,
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      submitLoading: false
    }
  },
  created() {
    const id = this.$route.query.id
    this.queryData.id = id
    if (id !== undefined) {
      this.pageType = 'edit'
      this.loadInfo(id)
    }
  },
  methods: {
    loadInfo(id) {
      new Promise((resolve, reject) => {
        rotationInfo({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.info = res.data
        this.fileList = res.data.file_list
        if (res.data.status === 1) {
          this.infoStatus = true
        } else {
          this.infoStatus = false
        }
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回
        // setTimeout(() => {
        //     this.$router.back(-1)
        // }, 2000)
      })
    },
    customRequest(updateData) { // 上传提交
      const file = updateData.file
      const formData = new FormData()
      formData.append('image', file)
      updateData.onProgress()
      this.saveFile(formData, updateData)
    },
    saveFile(formData, updateData) {
      new Promise((resolve, reject) => {
        uploadGeneralImage(formData).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        updateData.onSuccess(res)
      }).catch(error => {
        updateData.onError(error)
      })
    },
    removeFile(fileObj) {
      const path = fileObj.file_path
      if (path === undefined) {
        return true
      }
      return new Promise((resolve, reject) => {
        deleteGeneralFile({ path: path }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            // reject(response)
            resolve(response)
          }
        })
      })
    },
    changeFile(info) { // 上传文件中的三种状态调用
      let fileList = [...info.fileList]
      fileList = fileList.map(file => {
        if (file.response) {
          file.save_name = file.response.data.save_name
          file.file_path = file.response.data.file_path
          file.file_url = file.response.data.file_url
        }
        return file
      })
      this.fileList = fileList
      if (info.file.status !== 'uploading') {
        // 上传中
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败,` + info.file.error.message)
      }
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.fileList.length === 0) {
            this.$notification.success({
              message: '错误',
              description: '请选择轮播图'
            })
            return false
          }
          values.status = values.status ? 1 : 0
          values.img_url = this.fileList[0].file_url
          values.img_path = this.fileList[0].file_path
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              rotationUpdate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              rotationCreate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回列表
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
